import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

const items = [
    { name: "Artifacts", link: "/cosmetic/artifact", newTab: false },
    { name: "Capes", link: "/cosmetic/cape", newTab: false },
    { name: "Projectiles", link: "/cosmetic/projectile", newTab: false },
    { name: "Killphrases", link: "/cosmetic/killphrase", newTab: false },
    { name: "Profile", link: "/profile", newTab: false },
    { name: "Store", link: "https://store.zeqa.net/", newTab: true },
    { name: "Discord", link: "https://discord.com/invite/zeqa", newTab: true },
];

export default function AppNavigation() {
    const [open, setOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const [username, setUsername] = useState(localStorage.getItem("username") || "");
    const [isLoggedIn, setIsLoggedIn] = useState(!!username);
    const [showAccountOptions, setShowAccountOptions] = useState(false);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const location = useLocation();
    const navigate = useNavigate();
    const menuRef = useRef(null);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth >= 768) {
            setOpen(false);
        }
    };

    const handleCloseMenu = useCallback(() => {
        if (open) {
            setClosing(true);
            setTimeout(() => {
                setOpen(false);
                setClosing(false);
            }, 500);
        }
    }, [open]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleCloseMenu();
            }
        };
        window.addEventListener("resize", handleResize);
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [handleCloseMenu]);

    const handleNavigation = (item) => {
        if (item.newTab) {
            window.open(item.link, "_blank");
        } else {
            navigate(item.link);
            handleCloseMenu();
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const inputUsername = prompt("Enter your username:");
        if (inputUsername) {
            setIsLoading(true);
            setError("");
            try {
                const response = await axios.get(`https://app.zeqa.net/api/player/item/${inputUsername}`, {
                    crossdomain: true,
                });
                if (response.status === 200 && response.data.result.length > 0) {
                    setUsername(inputUsername);
                    localStorage.setItem("username", inputUsername);
                    setIsLoggedIn(true);
                    setError("");
                } else {
                    setError("Error");
                    setIsLoggedIn(false);
                    setTimeout(() => {
                        setError("");
                    }, 3000);
                }
            } catch (err) {
                setError("Login Error");
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleLogout = () => {
        setUsername("");
        localStorage.removeItem("username");
        setIsLoggedIn(false);
    };

    return (
        <div className="app_navigation_container">
            <div className="zeqa-inner-container-center">
                <div data-animation="over-left" data-collapse="medium"
                    data-duration="400" data-easing="ease-in" data-easing2="ease-out" role="banner"
                    className="zeqa-header-wrapper zeqa-nav">
                    <div className="zeqa-container-default zeqa-container">
                        <div className="zeqa-header-content-wrapper">
                            <div
                                className="zeqa-header-logo-link zeqa-nav-brand zeqa-current"
                                aria-label="home"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/");
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src="https://app.zeqa.net/image/winter.webp"
                                    srcSet="https://app.zeqa.net/image/winter.webp 500w, https://app.zeqa.net/image/winter.webp 800w, https://app.zeqa.net/image/winter.webp 1080w, https://app.zeqa.net/image/winter.webp 1600w, https://app.zeqa.net/image/winter.webp 2560w"
                                    width="124"
                                    sizes="(max-width: 479px) 104.15625px, (max-width: 767px) 111.59375px, 124px"
                                    alt="Zeqa Logo"
                                    className="zeqa-header-logo"
                                />
                            </div>

                            <div className="app_navigation_menu" ref={menuRef}>
                                <div
                                    className="zeqa-hamburger-menu-wrapper zeqa-nav-button"
                                    style={{ WebkitUserSelect: 'text' }}
                                    aria-label="menu"
                                    role="button"
                                    tabIndex="0"
                                    aria-controls="zeqa-nav-overlay"
                                    aria-haspopup="menu"
                                    aria-expanded={open ? "true" : "false"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (open) {
                                            handleCloseMenu();
                                        } else {
                                            setOpen(true);
                                        }
                                    }}>
                                    <img
                                        src="https://app.zeqa.net/image/toggles/navbarfilter.png"
                                        alt="Filters"
                                        style={{ maxWidth: '47px', maxHeight: '47px', width: 'auto', height: 'auto' }}
                                    />
                                </div>
                                <div
                                    className={`app_navigation_menu_select ${open ? "open" : ""} ${closing ? "closing" : ""}`}
                                >
                                    <nav
                                        role="navigation"
                                        className="zeqa-header-menu-wrapper zeqa-nav-menu"
                                        data-nav-menu-open={open ? "" : undefined}
                                    >
                                        <ul className="zeqa-header-nav-menu-list">
                                            {items.map((item, index) => (
                                                <li key={index} className={`${location.pathname === item.link ? "active" : ""}`}>
                                                    <a href={item.link} className="navigation-links nav-link" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleNavigation(item);
                                                    }}>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                            <div className="login-div">
                                                <img
                                                    src={username ? `https://api.zeqa.net/api/players/avatars/${username}` : "https://api.zeqa.net/api/players/avatars/steve"}
                                                    loading="lazy"
                                                    width="38"
                                                    alt="profile"
                                                    className="profile-image"
                                                />
                                                {isLoggedIn ? (
                                                    <div
                                                        onMouseEnter={() => windowWidth >= 1280 && setShowAccountOptions(true)}
                                                        onMouseLeave={() => windowWidth >= 1280 && setShowAccountOptions(false)}
                                                    >
                                                        {(showAccountOptions || windowWidth < 1280) && (
                                                            <div className="account-links" style={{ display: 'flex', flexDirection: windowWidth < 1280 ? 'column' : 'row' }}>
                                                                <a
                                                                    href={`/profile?player=${username}`}
                                                                    className="navigation-links nav-link"
                                                                >
                                                                    Profile
                                                                </a>
                                                                <a href="/profile" onClick={handleLogout} className="navigation-links nav-link">
                                                                    Logout
                                                                </a>
                                                            </div>
                                                        )}
                                                        {!showAccountOptions && windowWidth >= 1280 && (
                                                            <a href={`/profile?player=${username}`} className="navigation-links nav-link">
                                                                {username}
                                                            </a>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="login-state-wrapper">
                                                        {!error && (
                                                            <a href="/profile" onClick={handleLogin} className="navigation-links nav-link">
                                                                {isLoading ? "Logging in..." : "Login"}
                                                            </a>
                                                        )}
                                                        {error && (
                                                            <span
                                                                className="navigation-links nav-link"
                                                                style={{
                                                                    color: "red",
                                                                    fontFamily: "Minecraft Seven",
                                                                }}
                                                            >
                                                                {error}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
